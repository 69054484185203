$(document).ready(function () {

    if ($('.js-header').length){
        $(window).scroll(function () {
            if ($(document).scrollTop() > 50) {
                $('.js-header').addClass('active');
            }
            else {
                $('.js-header').removeClass('active');
            }
        });
    }
    
    

 /* LOCK / RELEASE SCROLL */
    function getScrollBarWidth() {
        let el = document.createElement("div");
        el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
        document.body.appendChild(el);
        let width = el.offsetWidth - el.clientWidth;
        el.remove();
        return width;
    }
    window.scrollBarWidth = getScrollBarWidth();
    document.documentElement.style.setProperty('--scrollbarWidth', window.scrollBarWidth + "px");

    window.lockScroll = function () {
        let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
        document.querySelector('html').classList.add('noscroll');
        document.querySelector('html').style.top = -scrollTop + 'px';
    }
    window.releaseScroll = function () {
        let scrollTop = parseInt(document.querySelector('html').style.top);
        document.querySelector('html').classList.remove('noscroll');
        document.querySelector('html,body').scrollTop = -scrollTop;
    }

    

/* MENU */
    $('.js-open-menu').on('click', function () {
        lockScroll();
        $('.overlay').fadeIn(200);
        $(this).parent().addClass('open');
        $('.js-menu').addClass('open');
    });

    $('.js-close-menu').on('click', function () {
        releaseScroll();
        $('.overlay').fadeOut(200);
        $(this).parent().removeClass('open');
        $('.js-menu').removeClass('open');
    });



// Fonction pour vérifier si l'élément est visible à l'écran
    function elementIsInView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

// Fonction pour ajouter la classe "active" à l'élément quand il est visible
    function goutteAnim() {
        $('.js-goutte-anim').each(function () {
            if (elementIsInView($(this))) {
                $(this).addClass('active');
            }
        });
    }

    

// Ajouter la classe "active" aux éléments déjà visibles au chargement de la page
    goutteAnim();

    // Événement de scroll pour vérifier et ajouter la classe "active" lorsque l'élément devient visible
    $(window).scroll(function () {
        goutteAnim();
    });

    
    /* FILTERS CARDS */

    var checkedFiltersCards = [];

    // Function to update the arrays when a checkbox is clicked
    function updateFiltersCheckedValues(value, checked, filter) {
        var filterCardsArray;

        switch (filter) {
            case 'filter':
                filterCardsArray = checkedFiltersCards;
                break;
            default:
                return;
        }

        if (checked) {
            // Add the value to the array if it's checked and not already present
            if (filterCardsArray.indexOf(value) === -1) {
                filterCardsArray.push(value);
            }
        } else {
            // Remove the value from the array if it's unchecked and present
            var index = filterCardsArray.indexOf(value);
            if (index !== -1) {
                filterCardsArray.splice(index, 1);
            }

        }
        // Call the function to filter the items based on the checked criteria
        filterCardsItems();
    }

    // Function to filter the items based on the checked criteria
    function filterCardsItems() {
        // Show all items first
        $('.js-filteredCard').show();
        // Apply Filter 1
        if (checkedFiltersCards.length > 0) {
            $('.js-filteredCard').not('[data-filters*="' + checkedFiltersCards.join('"], [data-filters*="') + '"]').hide();
        }
    }

    // Attach a click event handler to checkboxes of Filter 1
    $('.js-filter').on('click', function () {
        var value = $(this).val();
        var isChecked = $(this).prop('checked');
        updateFiltersCheckedValues(value, isChecked, 'filter');
    });

    $('.js-filters-reinit').on('click', function () {
        $('.js-filter').prop("checked", false);
        $('.js-filteredCard').show();
        checkedFiltersCards = [];
    });


    



});
